.home-teaser .grid-teaser-item {
  position: relative;
}
.home-teaser .grid-teaser-item:hover .grid-image-wrapper:after {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  opacity: 0.6;
}
.home-teaser .grid-teaser-item:hover .content {
  top: 50%;
  opacity: 1;
}
.home-teaser .grid-image-wrapper {
  position: relative;
  margin-bottom: 0 !important;
}
.home-teaser .grid-image-wrapper img {
  height: 245px !important;
}
.home-teaser .content {
  position: absolute;
  z-index: 10;
  top: 60%;
  left: 50%;
  display: block;
  width: 80% !important;
  padding: 0.5rem 0;
  color: #fefefe;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.4s;
}
.home-teaser .content h2 {
  splay: none;
}
.icon-teaser {
  text-align: center;
}
.icon-teaser .grid-teaser-item {
  flex-direction: column;
  color: #505050;
}
.icon-teaser a {
  display: inline-block;
}
.icon-teaser a:hover i.circular.icon {
  background-color: rgba(33, 129, 176);
  color: white;
}
.icon-teaser a:hover .small-break {
  width: 75px;
  background-color: rgba(33, 129, 176);
}
.icon-teaser i.circular.icon {
  width: 4em !important;
  height: 4em !important;
  padding: 1.5em 0 !important;
  margin: 0 auto;
  box-shadow: 0em 0em 0em 0.1em rgba(33, 129, 176) inset;
  color: rgba(33, 129, 176);
}
.icon-teaser .content p {
  text-align: center;
}
.icon-teaser .small-break {
  display: block;
  width: 40px;
  height: 1px;
  border: none;
  margin: 10px auto 15px;
  background-color: #ebebeb;
  transition: all 0.2s linear;
}
.icon-teaser h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: rgba(33, 129, 176);
  font-size: 18px !important;
}
.section-head-teaser {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  padding: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: -50vw !important;
}
.section-head-teaser .grid-teaser-item {
  display: flex;
  flex-direction: column;
}
.section-head-teaser .grid-teaser-item .grid-image-wrapper {
  display: flex;
  height: 417.6px;
  margin-right: 0 !important;
}
.section-head-teaser .grid-teaser-item .grid-image-wrapper img {
  -o-object-position: center;
     object-position: center;
}
.section-head-teaser .grid-teaser-item .content {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  transform: translate(-50%, -50%);
}
.section-head-teaser .grid-teaser-item .content h3 {
  margin-top: 10%;
  font-family: Montserrat, Helvetica Neue, Arial, Helvetica, Geneva, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.section-head-teaser .grid-teaser-item .content p {
  font-size: 18px;
  text-align: center;
}
