.statistics-text-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  padding: 130px;
}
.statistics-text-wrapper .ui.statistics .ui.statistic .slate p {
  margin-bottom: 0;
  color: inherit;
}
.statistics-text-wrapper .ui.statistics.left .ui.statistic {
  align-items: flex-start;
}
.statistics-text-wrapper .ui.statistics.center .ui.statistic {
  align-items: center;
}
.statistics-text-wrapper .ui.statistics.right .ui.statistic {
  align-items: flex-end;
}
